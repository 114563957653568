/* Global styles
-------------------------------------------------- */
body.slidehub_body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, "Arial", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "游ゴシック", YuGothic, sans-serif;
  font-size: 16px;
  line-height: 1.42857;
}

.slidehub_body h1,
.slidehub_body h2,
.slidehub_body h3,
.slidehub_body h4,
.slidehub_body h5,
.slidehub_body h6,
.slidehub_body .h1,
.slidehub_body .h2,
.slidehub_body .h3,
.slidehub_body .h4,
.slidehub_body .h5,
.slidehub_body .h6 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, "Arial", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-weight: 300;
  line-height: 1.2;
}

.slidehub_body a,
.slidehub_body a:hover,
.slidehub_body a:visited,
.slidehub_body a:active {
  overflow: hidden;
  outline: none;
}

/* Main Area styles
-------------------------------------------------- */
.slidehub_body #main_container {
  max-width: 1024px;
  background-color: transparent;
  padding-top: 60px;
  margin-top: 1em;
  border: none;
}

.slidehub_body div.row a,
.slidehub_body div.row a:hover,
.slidehub_body div.row a:visited,
.slidehub_body div.row a:active {
  text-decoration: none;
}

.slidehub_body span.no_decoration a,
.slidehub_body span.no_decoration a:hover,
.slidehub_body span.no_decoration a:visited,
.slidehub_body span.no_decoration a:active {
  text-decoration: none;
}

.slidehub_body .bottom_padding_20 {
  padding-bottom: 20px;
}

/* Slide Item Area styles
-------------------------------------------------- */
.slidehub_body div#slide_div_box {
  background-color: #fff !important;
  border: 1px solid #ccc;
  padding: 20px !important;
  margin-top: 20px;
  margin-bottom: 20px
}

.slidehub_body div.slide_description {
  padding: 0.8em;
  margin-top: 0.8em;
  padding-bottom: 0px;
  text-align: justify;
}

.slide_description .item {
  font-size: 0.9em;
  display: flex;
  align-items: flex-start;
  /* 番号と内容を揃える */
  margin-bottom: 10px;
  /* 各項目間の余白 */
}

.slide_description .number {
  min-width: 30px;
  /* 番号の幅を固定 */
  text-align: right;
  /* 番号を右寄せ */
  padding-right: 10px;
  /* 番号と文字の間の余白 */
  font-weight: bold;
  /* 番号を強調 */
}

.slide_description .content {
  flex: 1;
  /* 残りのスペースを埋める */
  text-align: left;
  /* テキストを左揃え */
}

.slidehub_body div.slide_detail_row {
  /** reserve **/
}

/* Show slide vertically
-------------------------------------------------- */
.slidehub_body div.vertical_slide_box {
  border: 1px solid #000;
  margin-bottom: 0px;
}

.slidehub_body div.icon_right {
  text-align: right;
}

.slidehub_body div.icon_right span {
  color: #bbb;
}

/* Slide Card styles
-------------------------------------------------- */
.slidehub_body .slidecard .slidecard-image {
  overflow: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  /** border-bottom: 1px solid #ccc; **/
}

.slidehub_body .slidecard .slidecard-image img {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.slidehub_body .slidecard .slidecard-image:hover img {
  /*-webkit-transform: scale(1.2) rotate(-7deg);
  -moz-transform: scale(1.2) rotate(-7deg);
  -ms-transform: scale(1.2) rotate(-7deg);
  -o-transform: scale(1.2) rotate(-7deg);
  transform: scale(1.2) rotate(-7deg);*/
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.slidehub_body .slidecard {
  position: relative;
  margin-top: 0px;
  position: relative;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 4 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.slidehub_body .slidecard .slidecard-content {
  padding: 10px;
}

.slidehub_body .slidecard .slidecard-basic-info {
  height: auto;
}

.slidehub_body .slidecard .slidecard-content .slidecard-title,
.slidehub_body .slidecard-reveal .slidecard-title {
  font-size: 0.9em;
  font-weight: bold !important;
  height: 2.4em;
  margin-bottom: 0.4em;
  overflow: hidden;
  vertical-align: middle;
  display: table-cell;
}

.slidehub_body .slidecard .slidecard-content .slidecard-description {
  font-size: 0.8em;
  height: 3em;
  overflow: hidden;
}

.slidehub_body .slidecard .slidecard-action {
  padding: 20px;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
}

.slidehub_body .slidecard .slidecard-action a {
  font-size: 15px;
  text-transform: uppercase;
  margin-right: 20px;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.slidehub_body .slidecard .slidecard-action a:hover {
  text-decoration: none;
}

.slidehub_body .slidecard .slidecard-information {
  padding: 2px;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
  font-size: 0.6em;
}

.slidehub_body .slidecard .slidecard-reveal {
  padding: 20px;
  position: absolute;
  background-color: #FFF;
  width: 100%;
  overflow-y: auto;
  /*top: 0;*/
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 1;
  display: none;
}

.slidehub_body .slidecard .slidecard-reveal p {
  margin: 20px;
}

/* Avatar styles
-------------------------------------------------- */
.slidehub_body div.avatar50 {
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  height: 50px !important;
  width: 50px;
  overflow: hidden;
  text-align: center;
}

.slidehub_body .avatar50 img {
  width: 50px;
  height: 50px;
}

.slidehub_body .avatar20 img {
  width: 18px;
  height: 18px;
  border: 1px solid #666;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  padding: 0px;
}

/* Profile sidebar styles
-------------------------------------------------- */
.slidehub_body .profile-sidebar {
  margin-top: 20px;
  padding: 20px 0 10px 0;
}

.slidehub_body .profile-userpic img {
  float: none;
  margin: 0 auto;
  width: 40%;
  height: 40%;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.slidehub_body .profile-usertitle {
  text-align: center;
  margin-top: 14px;
}

.slidehub_body .profile-usertitle-name {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 7px;
}

.slidehub_body .profile-usertitle-job {
  text-align: left;
  font-size: 0.8em;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 15px;
}

.slidehub_body .profile-userbuttons {
  text-align: center;
  margin-top: 10px;
}

.slidehub_body .profile-userbuttons .btn {
  font-size: 11px;
  font-weight: 600;
  padding: 6px 15px;
  margin-right: 5px;
}

.slidehub_body .profile-userbuttons .btn:last-child {
  margin-right: 0px;
}

.slidehub_body .profile-usermenu {
  margin-top: 30px;
}

/* Comment Form styles
-------------------------------------------------- */
.slidehub_body .comment_post {
  padding-top: 8px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

/* Share Button styles
-------------------------------------------------- */
.slidehub_body ul.share-buttons {
  list-style: none;
  padding: 0;
}

.slidehub_body ul.share-buttons li {
  display: inline;
}

/* Tag styles
-------------------------------------------------- */
.tag1 {
  font-size: 1.0em;
}

.tag2 {
  font-size: 1.2em;
}

.tag3 {
  font-size: 1.4em;
}

.tag4 {
  font-size: 1.6em;
}

/* Error styles
-------------------------------------------------- */
.slidehub_body #error_explanation {
  color: red;

  ul {
    color: red;
    margin: 0 0 30px 0;
  }
}

.slidehub_body .field_with_errors .form-control {
  border: 1px solid red;
}

.slidehub_body div.alert {
  margin-top: 20px !important;
  margin-bottom: 0px !important;
}

abbr {
  display: none;
}

/* Narrow Window styles
-------------------------------------------------- */
@media only screen and (max-width: 760px) {
  .slidehub_body div#slide_div_box2 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}